import {  Modal } from 'react-bootstrap';
import styled from "styled-components";
import { CustomDropDown } from '../../PeopleHomeV2/styles';
const MediumFont = 'Rubik-Regular';

const BoldFont = 'Rubik-Bold';

const StyledModal = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({transform}) => transform && 'translate(0, 80px) translate(0, -90%) !important'};
		.modal-content{
      width: ${({newWidth}) => newWidth ? newWidth : '100%'};
	  height: auto;
	  box-shadow: none;
	  position: absolute;
    border-radius: 10px;
    border: 0px;
			.modal-header {
        display: flex;
        justify-content: space-between;
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
                background: linear-gradient(0deg, #8AD3F7 0%, #52A8ED 100%);
				border-radius: 6px 6px 0px 0px;
				height: ${({border}) => border ? '50px' : 'auto'};
        
				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
      .btn-close{
        display: none;
      }
			.modal-header .close {
				margin-top: -2px;
				outline: none;
				display: none;
			.cross_img {
				float: right;
				> img {
					width: 20px;
					height: 20px;
					cursor: pointer;
				}
			}
		}
    .modal-body{
      padding: 25px 0;
    }
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const StyledTitle = styled.div`
  font-family: Rubik-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: ${({textAlign}) => textAlign ? 'center' : 'left'};
  color: ${({color}) => color ? '#005c87' : '#ffff'};
  padding-bottom: ${({paddingBottom}) => paddingBottom ? '15px' : 'none'};
  padding-left: 25px;
  padding-right: 25px;
  margin-top: ${({marginBottom}) => marginBottom ? '25px' : 'none'};
`;

const StyledBody = styled(Modal.Body)`
 width: 100%;
`;
const CompayContainer = styled.div`
   width: 100%
   display: flex;
   padding-left: 25px;
   padding-right: 25px;
`;

const NewContainer = styled.div`
   width: 100%
    padding-top: 97px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
const PointButton = styled.button`
    margin: 10px;
    width: 97%; !important
	padding: 5px;
    height: 60px;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 6px;
     display: flex;
   > p {
	   margin: none;
	   padding: 18px;
}
   }
`;


const CompanyButton = styled.button`
    width: 100%;
	padding: 10px;
    height: 60px;
    border: 1px solid #aac9d7;
    box-sizing: border-box;
    border-radius: 6px;
     display: flex;
	 background-color: #f2f7f9;
.image_cls
{
	> img {
		width: 40px;
		height: 40px;
	 } 
}
 .name {
	padding: 10px 15px;
  color: #005c87;
  font-size: 16px;
 }

 .new_image {
    display: flex;
    justify-content: end;
	  height: 36px;
    align-items: center;
    margin: auto;
    margin-right: 10px;
	 > img {
		 width: 20px;
		 height: 20px;
	 } 
 }

`;

const DropDown = styled(CustomDropDown)`
background-color: #faf9f4;
  width: ${({width}) => width ? width : '100%'};
  box-shadow: none;
  padding: ${({padding}) => padding ? padding : '9px 10px'};
  color: #005c87;
  border: 1px solid #b3cfdb;
  .title {
    overflow: auto;
    text-overflow: ellipsis;
    font-family: ${MediumFont};
    float: left;
    max-width:250px;
    white-space:nowrap;
  }
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: #faf9f4;
    color: #005c87;
    border: 1px solid #b3cfdb;
  }
  .caret{
    width: 0px;
    display: none;
  }
`;

const NewDropDown = styled.div`
  background-color: #EDEDED;
  width: 100%;
  box-shadow: none;
  padding: 9px 10px;
  .title {
    overflow: auto;
    text-overflow: ellipsis;
    font-family: ${MediumFont};
    float: left;
    max-width:250px;
    white-space:nowrap;
  }
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: #EDEDED;
    color: rgb(51,51,51);
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  label {
    width: 100%
  }
  .btn-group {
    width: 100%;
    height: 47px;
    left: 1px;
    .title {
    }
  }

  .dropdown-menu {
    width: 100%;
    height: auto;
    max-height: 146px;
    overflow-x: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    text-align: center;
    ::-webkit-scrollbar {
     width: 5px;
     height: 5px;
   }
    ::-webkit-scrollbar-thumb {
     background: #99999C;
     cursor: pointer;
     border-radius: 2.5px;
   } 

    ::-webkit-scrollbar-track {
     background: #fff;
     border-radius: 2.5px;
   }
    > li {
        > a:  {
          padding: 5px 15px;

        }
        a:hover {
          background-color: #69c2FF;
          color: blue;
          opacity: 0.4;
          border-radius: 3px;
        }
      }
    > ul {
     width: 100%;
    }
    .right_cross_icon {
      width: 10px;
      height: 20px;
    }
  }
  
`;


export {StyledModal,StyledTitle,StyledBody,CompayContainer,CompanyButton,NewContainer,PointButton,DropDown,NewDropDown, ContentWrapper};
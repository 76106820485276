/* eslint-disable no-console,no-undef,camelcase */
import React, {Component} from "react";
import PropTypes from 'prop-types';
import moment from "moment";
import {ChallengesContainer, CommonContainer, TopChallengeContent, TopChallenges, TopChallengesImage} from "./styles";
import ImageThumbnail from "../ImageThumbnail";
import {ImageUrl} from "../../utils/constants";
import {convertDateInTimezone, getLength, getTimeToShow} from "../../utils/methods";
import momentTZ from "moment-timezone";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class TopChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: []
    };
  }

  componentDidMount() {
    const { challenge } = this.props;
    this.setState({
      time: getTimeToShow(challenge, 'challenge')
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.challenge.id !== this.props.challenge.id) {
      this.setState({
        time: getTimeToShow(this.props.challenge, 'challenge')
      });
    }
  }
  fallBack = (e) => {
    e.target.src = '/public/images/fallback.png'
  }

  render () {
    const { time } = this.state;
    const { challenge, history, challengeAttendees} = this.props;
    let challengeId = challenge.id;
    return(
      <CommonContainer textAlign padding="0" divideCards={1}float={1} >
        <ChallengesContainer noPadding={1}>
          <TopChallenges>
            <TopChallengesImage onClick={() => history.push(`/challenges/${challengeId}`)}>
              <ImageThumbnail src={`${ImageUrl}/${challenge.image}`} alt="no image"/>
            </TopChallengesImage>
            <TopChallengeContent>
              <div className="challenge">
                <div className="title">
                  {challenge.title}
                </div>
                <div className="challenge-details">
                  <div className="data">
                    Challenge Length: <span>{getLength(challenge.weeks, challenge.remaining_days, challenge.hours, challenge.minutes)}</span>
                  </div>
                  <div className="data">
                    Start Date: <span>{moment(convertDateInTimezone(challenge.start_date)._d).format('MMM DD, YYYY')}</span>
                  </div>
                  <div className="data">
                    End Date: <span>{moment(convertDateInTimezone(challenge.end_date)._d).format('MMM DD, YYYY')}</span>
                  </div>
                </div>
              </div>
              <div className="challenge-remaining">
                {
                  (challenge.challenge_status == "over") ?
                    <div className="challenge-text">
                      <span>Challenge Over</span>
                    </div> : (challenge.challenge_status === 'locked') ?
                      <div className="challenge-text">
                        <span>{time.map((step, index) => step + (index === (time.length - 1) ? '' : ', '))}</span>
                        {(convertDateInTimezone(challenge.start_date)._d <= momentTZ()._d ? <span> till challenge is over</span> : '')}
                      </div> : <div className="challenge-text">
                        <span style={{'text-transform' : 'capitalize'}}> Challenge {challenge.challenge_status}</span>
                      </div>
                }
              </div>
            </TopChallengeContent>
            <TopChallengeContent>
              <div className="challenge-info">
                About the challenge
              </div>
              <div className="info-text">
                <div>{challenge.body}</div>
              </div>
              <div className="challenge-user">
                <div className="user-info">
                  {challengeAttendees && challengeAttendees.map((user, index) => (
                    (index < 3) ? <span key={index}> <img onError={this.fallBack} src={`${ImageUrl}/${user.profile_image}`}/>
                    </span> : null
                  ))
                  }
                  {(challengeAttendees && challengeAttendees.length > 2) ?
                    <span className="circle">
                      {challengeAttendees && challengeAttendees.length - 2}+
                    </span>
                    : null
                  }
                  {
                    (challenge.challenge_status === 'locked') ?
                      <span className={"user-list"}>{challengeAttendees && challengeAttendees.length > 1 ? `${challengeAttendees && challengeAttendees.length} members have joined`: `${challengeAttendees && challengeAttendees.length} member has joined`}</span>
                      : <span className={"user-list"}>{challengeAttendees && challengeAttendees.length > 1 ? `${challengeAttendees && challengeAttendees.length} members have completed` : `${challengeAttendees && challengeAttendees.length} member has completed`}</span>
                  }
                </div>
              </div>
            </TopChallengeContent>
          </TopChallenges>
        </ChallengesContainer>
      </CommonContainer>
    );
  }
}

TopChallenge.propTypes = {
  challenge: PropTypes.object,
  history: PropTypes.object,
  challengeAttendees: PropTypes.array
};

const mapStateToProps = (state) => ({
  challengeAttendees: state.challenges.challengeAttendees,
});

export default connect(mapStateToProps, null)(withTranslation()(TopChallenge));




import React from 'react';
import PropTypes from 'prop-types';
import { ImageUrl } from '../../../utils/constants';
import { StyledModal, InitiativesContainer, ButtonContainer, ConfirmButton } from '../../ContentV2/SideBarContiner/Styles';
import { Modal } from "react-bootstrap";

class ThankYouPopup extends React.Component {

  showWellnessInitiatives = (selectedInitiative) => {
    const { showModal, thankYouPopup, value, onClose } = this.props;
    return (
      <StyledModal show={showModal} width={"449px"} height={"449px"} open={() => thankYouPopup()} >
        <Modal.Body>
          {selectedInitiative && selectedInitiative.length && selectedInitiative.map((data, index) =>
            (<InitiativesContainer key={index} color={data.points_color}>
              <div className={'greatText'} style={{color:'#005c87'}}>
                Great Job!
              </div>
              <div className="completionText" style={{color:'#005c87'}}>
                Your company initiative is confirmed!
              </div>
              <div className={'initiative-icon'} style={{color:'#005c87'}}>
                <img src={`${ImageUrl}/${data.initiative_image}`} alt="initiative" />
              </div>
              <div className="initiativeText" style={{color:'#005c87'}}>
                {data.initiative_name}
              </div>
              <div className="congratulationText" style={{color:'#005c87',fontFamily:'Rubik',fontSize:'16px'}}>
                {data.id === 1 || data.id === 2 ?
                  "Point value is set at +500 points." :
                  `Point value is set at +${value}`}
              </div>
              <ButtonContainer>
                <ConfirmButton background="#005c87" style={{width:'340px'}} onClick={() => onClose()}>Thank You</ConfirmButton>
              </ButtonContainer>
            </InitiativesContainer>))}
        </Modal.Body>
      </StyledModal>

    )
  };


  render() {
    const { selectedInitiative } = this.props;
    return (
      this.showWellnessInitiatives([selectedInitiative])
    );
  }

}
ThankYouPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  thankYouPopup: PropTypes.func,
  singleCompanyPopUp: PropTypes.func,
  onClose: PropTypes.func,
  history: PropTypes.object,
  value: PropTypes.string,
  wellnessInitiatives: PropTypes.array,
  selectedInitiative: PropTypes.array,
  companyInitiatives: PropTypes.array,
}

export default (ThankYouPopup);